import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="コンポーネント" mdxType="SEO" />
    <PageTitle title="コンポーネント" enTitle="Components" mdxType="PageTitle" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/38811f60344625d7f35fe0ae860ec47c/68947/components-index.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/38811f60344625d7f35fe0ae860ec47c/2aaaf/components-index.webp 160w", "/static/38811f60344625d7f35fe0ae860ec47c/85e47/components-index.webp 320w", "/static/38811f60344625d7f35fe0ae860ec47c/75198/components-index.webp 640w", "/static/38811f60344625d7f35fe0ae860ec47c/691bc/components-index.webp 960w", "/static/38811f60344625d7f35fe0ae860ec47c/223e5/components-index.webp 1280w", "/static/38811f60344625d7f35fe0ae860ec47c/3c2f7/components-index.webp 1281w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/38811f60344625d7f35fe0ae860ec47c/69538/components-index.png 160w", "/static/38811f60344625d7f35fe0ae860ec47c/72799/components-index.png 320w", "/static/38811f60344625d7f35fe0ae860ec47c/6af66/components-index.png 640w", "/static/38811f60344625d7f35fe0ae860ec47c/d9199/components-index.png 960w", "/static/38811f60344625d7f35fe0ae860ec47c/21b4d/components-index.png 1280w", "/static/38811f60344625d7f35fe0ae860ec47c/68947/components-index.png 1281w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/38811f60344625d7f35fe0ae860ec47c/6af66/components-index.png",
              "alt": "「Components」と書かれた吹き出しが腕を組む人から出ているイラスト",
              "title": "「Components」と書かれた吹き出しが腕を組む人から出ているイラスト",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`コンポーネントは、再利用可能なUIの構成要素です。各コンポーネントは、一貫性のあるスタイルとインタラクションを持ち、パターンによってユーザーが直感的に操作ができるよう設計されています。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/button/"
        }}>{`Button`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/checkbox/"
        }}>{`Checkbox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/list/"
        }}>{`List`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/modal/"
        }}>{`Modal`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/textfield/"
        }}>{`TextField`}</a></li>
    </ul>
    <p>{`※現在一部のコンポーネントのガイドのみ公開中です。`}</p>
    <h2 {...{
      "id": "コンポーネントカタログ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B3%E3%83%B3%E3%83%9D%E3%83%BC%E3%83%8D%E3%83%B3%E3%83%88%E3%82%AB%E3%82%BF%E3%83%AD%E3%82%B0",
        "aria-label": "コンポーネントカタログ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`コンポーネントカタログ`}</h2>
    <p>{`各コンポーネントの実装と表示についてはコンポーネントカタログを参照してください。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ameba-spindle.web.app/"
        }}>{`Web向けコンポーネントカタログ（Storybook）`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      